import React from 'react';
import bemCn from 'bem-cn';
import loadable from '@loadable/component';
import './style.scss';

const SniffDatepicker = loadable(() => import('../sniff-datepicker'));
const SniffCheckbox = loadable(() => import('../sniff-checkbox'));
const SniffTextarea = loadable(() => import('../sniff-textarea'));
const SniffSelect = loadable(() => import('../sniff-select'));
const SniffRadio = loadable(() => import('../sniff-radio'));
const SniffText = loadable(() => import('../sniff-text'));

const b = bemCn('sniff-input');

const inputConfig = {
    textarea: SniffTextarea,
    text: SniffText,
    email: SniffText,
    number: SniffText,
    password: SniffText,
    checkbox: SniffCheckbox,
    select: SniffSelect,
    radio: SniffRadio,
    datepicker: SniffDatepicker,
};

const SniffInput = ({ type, className, ...rest }) => {
    const InputComponent = inputConfig[type];
    return <InputComponent className={b.mix(className).toString()} type={type} {...rest} />;
};

export default SniffInput;
